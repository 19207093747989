<template>

    <div class="p-grid">
        <div class="p-col-9">

            <DataTable :value="Spiders" :paginator="true" :rows="20"  :loading="loading" v-model:filters="filters" filterDisplay="row"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            >

                <Column field="Name" header="Name" :sortable="true" :showFilterMenu="false">
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Search by name - ${filterModel.matchMode}`" v-tooltip.top.focus="'Hit enter key to filter'"/>
                    </template>
                </Column>

                <Column field="Type" header="Type" :sortable="true" :showFilterMenu="false">
                    <template #filter="{filterModel,filterCallback}">
                        <Dropdown :options="spidersTypes" v-model="filterModel.value" @change="filterCallback()" :showClear="true" placeholder="Search by type" />
                    </template>
                </Column>

                <template #header>
                    Spiders list &mdash; {{ Spiders.length }}
                </template>

            </DataTable>

        </div>

        <div class="p-col-3">

                <div class="p-card p-shadow-3">
                    <h3>Self brands</h3>
                    {{ printArray(Lists.SelfBrands) }}
                </div>

                <div class="p-card p-shadow-3">
                    <h3>Rival Brands</h3>
                    {{ printArray(Lists.RivalBrands) }}
                </div>

                <div class="p-card p-shadow-3">
                    <h3>Comparators</h3>
                    {{ printArray(Lists.Comparators) }}
                </div>

                <div class="p-card p-shadow-3">
                    <h3>Keywords</h3>
                    {{ printArray(Lists.Keywords) }}
                </div>

        </div>

    </div>

</template>

<script>
import MetricService from "@/services/metrics";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {FilterMatchMode} from 'primevue/api';

export default {
    name: "EciLists",
    components: {
        DataTable, Column
    },
    data() {
        return {
            filters: {
                'Name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'Type': {value: null, matchMode: FilterMatchMode.EQUALS},
            },
            spidersTypes: [],
            loading: true,
            Account:null,
            Delta: 1,
            Data: null,
            Groups:[],
            Lists:{},
            Spiders:[{Name:'', Type:''}],
        }
    },
    service: null,
    created() {
        this.service = new MetricService()
    },
    watch: {
        Delta:function() {
            this.loadData()
        }
    },
    mounted() {
        this.Account = this.$router.currentRoute.value.params.account;
        this.loadData()

    },
    methods: {
        loadData() {
            this.loading = true
            this.service.getOverviewEci(this.Account, this.Delta).then(data => {
                let groups = {};
                for (const [, value] of Object.entries(data)) {
                    const grp = value["Field"]["Group"];

                    if (grp in groups) {
                        groups[grp].push(value)

                    } else {
                        groups[grp] = [value]
                        this.Groups.push(grp)
                    }
                }
                this.Data = groups
            })

            this.service.getListsEci(this.Account).then(data => {
                this.Lists = data
                let spidersList = []
                data.Spiders.forEach(k => {
                    let t = k.split("_")
                    if (t.length === 1) {
                        t[1] = "items"
                    }
                    spidersList.push(
                        {Name:t[0], Type:t[1]}
                    )

                    if (this.spidersTypes.includes(t[1]) === false) {
                        this.spidersTypes.push(t[1])
                    }
                })
                this.Spiders = spidersList
                this.loading = false
            })

        }
    }
}
</script>
<style scoped>
h3 {
    margin: 0;
    padding-bottom: 10px;

}
.p-card {
    padding:5px;
    margin-bottom:10px;
    font-size:11pt;
}
</style>